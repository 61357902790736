import React, {Component} from 'react';
import {Textbox} from "./Textbox";
import PropTypes from 'prop-types';
import {scrollToTop} from "../helpers";
import strings from "../strings";
import api from "../api";
import alerts from "./Alerts";

export class LoginConfirm extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired
  };

  componentDidMount() {
    scrollToTop();
  }

  handleLogin = (user) => {
    user.employmentTypeVerified = true;
    this.props.login(user).then(success => {
      if (success) {
        this.props.history.go(-1);
      } else {
        // Could not log in
        alerts.error(strings.loginFailed);
      }
    });
  };

  login = () => {
    this.handleLogin(this.props.location.state.user);
  };

  register = async () => {
    const result = await api.register({
      taxNumber: this.props.location.state.taxNumber,
      businessId: this.props.location.state.businessId,
      employmentType: this.props.location.state.user.employmentType
    });

    if (result.success) {
      // Successfully registered from remote source
      this.handleLogin(result.data);
    } else {
      // Some error occurred
      if (result.status === 400) {
        // Invalid tax number or business id
        alerts.error(strings.invalidTaxNumberOrBusinessId);
      } else if (result.status === 404) {
        // Could not find in remote source
        alerts.error(strings.notFoundInRemoteSource);
      } else {
        alerts.error(strings.errorOccurred);
      }
    }
  };

  submit = async (e) => {
    e.preventDefault();

    if (this.props.location.state.user.id) {
      // Existing registered user
      this.login()
    } else {
      // Register user from remote source
      await this.register();
    }
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    return (
      <form className="login confirm" onSubmit={this.submit}>
        <p>{strings.verifyInfo}</p>
        <Textbox value={this.props.location.state.user.firstName} id="first-name" type="text"
                 label={strings.firstName} required={true} disabled={true}/>
        <Textbox value={this.props.location.state.user.lastName} id="last-name" type="text"
                 label={strings.lastName} required={true} disabled={true}/>
        <Textbox value={this.props.location.state.user.companyName} id="company-name" type="text"
                 label={strings.companyName} required={true} disabled={true}/>
        <ul>
          <li>
            <button type="submit">{strings.next}</button>
          </li>
          <li>
            <button className="link" onClick={this.goBack}>{strings.back}</button>
          </li>
        </ul>
      </form>
    );
  }
}
