export const DirectEmployment = 1;
export const LeasedEmployee = 2;
export const IndependentContractor = 3;
export const UnpaidWork = 4;

const employmentTypes = [
  DirectEmployment,
  LeasedEmployee,
  IndependentContractor,
  UnpaidWork
];

export function isValid(value) {
  return employmentTypes.includes(value);
}
