import {confirmAlert} from "react-confirm-alert";
import "./Alerts.css";
import strings from "../strings";

function alertsCallback(handler) {
  const func = handler;

  function callback() {
    if (!func) {
      return;
    }

    try {
      func();
    } catch (e) {

    }
  }

  return {
    callback
  };
}

const alerts = (function () {
  function error(message, func) {
    confirmAlert({
      closeOnClickOutside: false,
      title: strings.errorTitle,
      message,
      buttons: [
        {
          label: strings.ok,
          onClick: alertsCallback(func).callback
        }
      ]
    });
  }

  return {
    error
  };
})();

export default alerts;
