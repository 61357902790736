import './Textbox.css';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class Textbox extends Component {
  static propTypes = {
    valueRef: PropTypes.object,
    value: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.oneOf([
      "button",
      "checkbox",
      "color",
      "date",
      "datetime-local",
      "email",
      "file",
      "hidden",
      "image",
      "month",
      "number",
      "password",
      "radio",
      "range",
      "reset",
      "search",
      "submit",
      "tel",
      "text",
      "time",
      "url",
      "week"
    ]).isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    others: PropTypes.object
  };

  render() {
    const {id, name, valueRef, value, type, label, required, placeholder, disabled, onChange, others} = this.props;

    return (
      <label className={(disabled ? 'disabled ' : '') + 'textbox'}>
        <span className="label">{label}</span>
        <input ref={valueRef} value={value} type={type} id={id} name={name} required={required}
               placeholder={placeholder} disabled={disabled} onChange={onChange} {...others} />
      </label>
    );
  }
}
