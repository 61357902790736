import './StaticText.css';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class StaticText extends Component {
  static propTypes = {
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  };

  render() {
    const {value, label, disabled} = this.props;

    return (
      <div className={(disabled ? 'disabled ' : '') + 'static-text'}>
        <span className="label">{label}</span>
        <span className="value">{value}</span>
      </div>
    );
  }
}
