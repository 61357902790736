import React, {Component} from 'react';
import {Textbox} from "./Textbox";
import api from "../api";
import {scrollToTop} from "../helpers";
import config from "../config";
import strings from "../strings";
import alerts from "./Alerts";
import Select from "react-select";
import "./LoginManual.css";
import {handleBusinessId} from "../input_handlers";
import * as EmploymentType from "../constants/EmploymentTypes";

export class LoginManual extends Component {
  state = {
    input: {
      number: {value: '', isValid: false},
      businessId: {value: '', isValid: false},
      employmentType: {value: 0, isValid: false},
    }
  };

  componentDidMount() {
    scrollToTop();
  }

  register = async (e) => {
    e.preventDefault();

    const taxNumber = this.state.input.number.value;
    const businessId = this.state.input.businessId.value;
    const employmentType = this.state.input.employmentType.value;
    const result = await api.getUser({taxNumber, businessId, employmentType});

    if (result.success) {
      if (result.data.id) {
        // The registration exists from before
        this.props.history.push({
          pathname: '/login/confirm',
          state: {
            user: result.data
          }
        });
      } else {
        // The data can be provided from external source
        this.props.history.push({
          pathname: '/login/confirm',
          state: {
            taxNumber: taxNumber,
            businessId: businessId,
            user: result.data
          }
        });
      }
    } else {
      // Some error occurred
      if (result.status === 404) {
        // Tax number not found
        this.props.history.push({
          pathname: '/register',
          state: {
            taxNumber: taxNumber,
            businessId: businessId,
            employmentType: employmentType
          }
        });
      } else {
        alerts.error(strings.errorOccurred);
      }
    }
  };

  isValid = () => {
    const keys = Object.keys(this.state.input);

    for (let index in keys) {
      const key = keys[index];

      if (!this.state.input[key].isValid) {
        return false;
      }
    }

    return true;
  };

  handleNumber = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    let newValue = e.currentTarget.value.replace(/\D/g, '').substr(0, config.taxNumber.length);

    state.input[key].value = newValue;
    state.input[key].isValid = newValue && newValue.length === config.taxNumber.length;

    this.setState(state);
  };

  handleBusinessId = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    const oldValue = state.input[key].value;
    const {value, isValid} = handleBusinessId(oldValue, e.currentTarget.value);

    state.input[key].value = value;
    state.input[key].isValid = isValid;

    this.setState(state);
  };

  handleSelect = (e) => {
    const state = {...this.state};
    let newValue = parseInt(e.value.toString(), 10);

    state.input.employmentType.value = newValue;
    state.input.employmentType.isValid = newValue && EmploymentType.isValid(newValue);

    this.setState(state);
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    const optionValues = [
      {value: EmploymentType.DirectEmployment},
      {value: EmploymentType.LeasedEmployee},
      {value: EmploymentType.IndependentContractor},
      {value: EmploymentType.UnpaidWork}
    ];

    const formatOptionLabel = ({value}) => (
      <div className="employment-type">
        <span className="description">{strings[`employmentType${value}`]}</span>
      </div>
    );

    const selectList = (<Select
      className="employment-type"
      classNamePrefix="select"
      isSearchable={false}
      placeholder={strings.employmentType}
      formatOptionLabel={formatOptionLabel}
      options={optionValues}
      onChange={this.handleSelect}
    />);

    return (
      <form className="login manual" onSubmit={this.register}>
        <p>{strings.taxNumberDesc}</p>
        <Textbox
          value={this.state.input.number.value}
          name="number"
          key="number"
          type="number"
          label={strings.taxNumber}
          required={true}
          onChange={this.handleNumber}
          others={{pattern: '^\\d{12}$'}}/>
        <Textbox
          value={this.state.input.businessId.value}
          name="businessId"
          key="businessId"
          type="tel"
          label={strings.companyBusinessId}
          required={true}
          onChange={this.handleBusinessId}/>
        {selectList}
        <ul>
          <li>
            <button disabled={!this.isValid()} type="submit">{strings.next}</button>
          </li>
          <li>
            <button className="link" onClick={this.goBack}>{strings.back}</button>
          </li>
        </ul>
      </form>
    );
  }
}
