import React, {Component} from 'react';
import {Route, Switch} from "react-router";
import ToFrontPage from "./components/ToFrontPage";
import Layout from './components/Layout';
import {Home} from './components/Home';
import {LoginManual} from "./components/LoginManual";
import {LoginCard} from "./components/LoginCard";
import {LoginCardManual} from "./components/LoginCardManual";
import {LoginConfirm} from "./components/LoginConfirm";
import {Register} from "./components/Register";
import {RegistrationConfirmation} from "./components/RegisterConfirmation";
import {ProjectHome} from "./components/ProjectHome";

import './main.css';
import './icons.css';
import api from "./api";
import {ProjectDetails} from "./components/ProjectDetails";
import {ProjectReportConfirmation} from "./components/ProjectReportConfirmation";
import {VerifyEmployeeType} from "./components/VerifyEmployeeType";
import * as EmploymentType from "./constants/EmploymentTypes";
import alerts from "./components/Alerts";
import strings from "./strings";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      projects: []
    };

    const stateRef = localStorage.getItem('state');
    if (stateRef) {
      this.state = JSON.parse(stateRef);
    }
  }

  componentDidMount() {
    const self = this;

    // Handle case when the token has expired by trying to authenticate again and get a new token
    api.onAuthenticationRequest(function () {
      if (!self.state.user) {
        return false;
      }

      return api.authenticate({id: self.state.user.id});
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    localStorage.setItem('state', JSON.stringify(this.state));
  }

  loadProjects = async () => {
    const response = await api.getProjects();

    if (!response || response.success === false) {
      return false;
    }

    this.setState({
      projects: response.data
    });

    return true;
  };

  addProject = async (project) => {
    const projects = [...this.state.projects];
    const index = projects.findIndex((p) => p.number === project.number);

    if (index < 0) {
      projects.push(project);
    } else {
      projects[index] = project;
    }

    this.setState({projects});
  };

  login = async (user) => {
    const response = await api.authenticate({id: user.id});

    if (!response || response.success === false) {
      return false;
    }

    this.setState({user});

    await this.loadProjects();

    return true;
  };

  leaveProject = async () => {
    const result = await api.stopTimeCards();
    if (result.success) {
      api.logout();

      const state = {
        user: null,
        projects: []
      };

      this.setState(state);
    } else {
      alerts.error(strings.errorOccurred);
    }
  };

  logout = () => {
    api.logout();

    const state = {
      user: null,
      projects: []
    };

    this.setState(state);
  };

  setUserAuxData = (state) => {
    const user = {...this.state.user};
    user.aux = state;

    this.setState({user});
  };

  handleSignedInState = () => {
    if (api.isAuthenticated()) {
      return true;
    }

    if (this.state.user) {
      return api.authenticate({id: this.state.user.id});
    }

    return false;
  };

  worksAsLeasedEmployee = (result) => {
    const user = {...this.state.user};
    if (result) {
      user.employmentType = EmploymentType.LeasedEmployee;
    }

    user.employmentTypeVerified = true;

    this.setState({user});
  };

  render() {
    if (this.handleSignedInState()) {
      if (this.state.user.employmentTypeVerified) {
        return (
          <Layout logout={this.logout} leaveProject={this.leaveProject}>
            <Switch>
              <Route exact path='/' render={(props) => <ProjectHome {...props} projects={this.state.projects}
                                                                    user={this.state.user}
                                                                    setUserAuxData={this.setUserAuxData}/>}/>
              <Route exact path='/project-report' render={(props) => <ProjectDetails {...props}
                                                                                     addProject={this.addProject}/>}/>
              <Route exact path='/project-report/confirmation' component={ProjectReportConfirmation}/>
              <Route component={ToFrontPage}/>
            </Switch>
          </Layout>
        );
      }

      return (
        <Layout logout={this.logout} leaveProject={this.leaveProject}>
          <VerifyEmployeeType verify={this.worksAsLeasedEmployee}/>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/login/card' render={(props) => <LoginCard {...props} login={this.login}/>}/>
            <Route exact path='/login/card/manual'
                   render={(props) => <LoginCardManual {...props} login={this.login}/>}/>
            <Route exact path='/login/manual' component={LoginManual}/>
            <Route exact path='/login/confirm' render={(props) => <LoginConfirm {...props} login={this.login}/>}/>
            <Route exact path='/register' component={Register}/>
            <Route exact path='/register/confirmation'
                   render={(props) => <RegistrationConfirmation {...props} login={this.login}/>}/>
            <Route component={ToFrontPage}/>
          </Switch>
        </Layout>
      );
    }
  }
}
