import {animateScroll as scroll} from 'react-scroll';

export function scrollToTop() {
  setTimeout(() => {
    scroll.scrollToTop({
      delay: 0,
      smooth: false,
      duration: 0,
    });
  }, 20);
}

export function isValidProjectLoad(props) {
  return props.location &&
    props.location.state &&
    props.location.state.number > 0;
}
