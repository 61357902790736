import React, {Component} from 'react';
import {isValidProjectLoad, scrollToTop} from "../helpers";
import strings from "../strings";

export class ProjectReportConfirmation extends Component {
  constructor(props) {
    super(props);

    if (!isValidProjectLoad(props)) {
      props.history.replace('/');
    }
  }

  componentDidMount() {
    scrollToTop();
  }

  continue = (e) => {
    e.preventDefault();

    this.props.history.goBack();
  };

  render() {
    if (!isValidProjectLoad(this.props)) {
      return null;
    }

    return (
      <form className="project report confirm" onSubmit={this.continue}>
        <img src="/circle-check.svg" alt="Check" className="icon check"/>
        <p>
          {strings.currentlyReportingToProject}<br/>
          <strong>{this.props.location.state.number}</strong>
        </p>
        <button type="submit">{strings.next}</button>
      </form>
    );
  }
}
