import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {scrollToTop} from "../helpers";
import strings from "../strings";
import alerts from "./Alerts";

export class RegistrationConfirmation extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired
  };

  componentDidMount() {
    scrollToTop();
  }

  continue = (e) => {
    e.preventDefault();

    const user = {
      ...this.props.location.state.user,
      employmentTypeVerified: true
    };

    this.props.login(user).then(success => {
      if (success) {
        this.props.history.go(-1);
      } else {
        // Could not log in
        alerts.error(strings.loginFailed);
      }
    });
  };

  render() {
    return (
      <form className="register confirmation" onSubmit={this.continue}>
        <img src="/circle-check.svg" alt="Check" className="icon check"/>
        <p>
          {strings.registrationConfirmation}<br/>
          {strings.easierNextTime}
        </p>
        <button type="submit">{strings.next}</button>
      </form>
    );
  }
}
