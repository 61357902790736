import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {scrollToTop} from "../helpers";
import strings from "../strings";

export class Home extends Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    return (
      <form className="home">
        <img src="/card.svg" alt="Card" className="icon card"/>
        <p>{strings.introduction}</p>
        <Link to="/login/card" className="button">{strings.scanCard}</Link>
        <ul>
          <li><Link to="/login/manual">{strings.scanUnable}</Link></li>
          <li><Link to="/login/manual">{strings.scanNoCard}</Link></li>
        </ul>
      </form>
    );
  }
}
