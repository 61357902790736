import React, {Component} from "react";
import PropTypes from "prop-types";
import {scrollToTop} from "../helpers";
import strings from "../strings";

export class VerifyEmployeeType extends Component {
  static propTypes = {
    verify: PropTypes.func.isRequired
  };

  componentDidMount() {
    scrollToTop();
  }

  leased = (e) => {
    e.preventDefault();

    this.props.verify(true);
  };

  normal = (e) => {
    e.preventDefault();

    this.props.verify(false);
  };

  render() {
    return (
      <div className="employment-type">
        <p>
          {strings.worksAsLeasedEmployee}
        </p>
        <button onClick={this.leased}>{strings.yes}</button>
        <button onClick={this.normal}>{strings.no}</button>
      </div>
    );
  }
}
