import React, {Component} from 'react';
import {Header} from "./Header";
import strings from "../strings";

export default class Layout extends Component {
  render() {
    let logoutLayout = "";
    let leaveProjectLayout = "";

    if (this.props.logout) {
      logoutLayout = (
        <div className="logout">
          <p>{strings.notPersonalDevice}</p>
          <button className="link" onClick={this.props.logout}>{strings.logoutDesc}</button>
        </div>
      );
    }

      if (this.props.leaveProject) {
          leaveProjectLayout = (
              <div className="leaveProject">
                  <p>{strings.leavingProject}</p>
                  <button className="link" onClick={this.props.leaveProject}>{strings.leavingProjectDesc}</button>
              </div>
          );
      }

    return (
      <div className="body">
        <Header/>
        <div className="main">
          {this.props.children}
          {leaveProjectLayout}
          {logoutLayout}
        </div>
      </div>
    );
  }
}
