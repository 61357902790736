import config from './config';
import appState from './appState';
import * as jwt from "jsonwebtoken";
import strings from "./strings";

const api = (function () {
  let authorizationCallback = null;

  const getUrl = function (subPath) {
    return config.api.baseUrl + subPath.replace(/^\/+/, '');
  };

  const getDefaultRequestInit = function (method, jsonData) {
    const state = appState.get();
    let headers = {};

    if (state.token) {
      headers['Authorization'] = 'Bearer ' + state.token;
    }

    if (jsonData) {
      headers['Content-Type'] = 'application/json';
    }

    headers['Accept'] = 'application/json';
    headers['Accept-Language'] = strings.getLanguage();

    return {
      method,
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers,
      redirect: 'follow',
      body: jsonData ? JSON.stringify(jsonData) : null
    };
  };

  const parseResponse = async function (response) {
    const data = await response.json();
    return {
      success: response.ok,
      status: response.status,
      statusText: response.statusText,
      data
    };
  };

  async function fetchData(url, requestInit) {
    try {
      let response = await fetch(url, requestInit);

      if (response.status === 401 && authorizationCallback && (await authorizationCallback())) {
        // Callback returned true, retry if we are authenticated
        if (isAuthenticated()) {
          response = await fetch(url, requestInit);
        }
      }

      return await parseResponse(response);
    } catch (e) {
      return {
        success: false,
        status: 408,
        statusText: e,
        data: null
      };
    }
  }

  async function get(url) {
    return await fetchData(url, getDefaultRequestInit('GET', null));
  }

  async function post(url, payload) {
    return await fetchData(url, getDefaultRequestInit('POST', payload));
  }

  async function patch(url, payload) {
    return await fetchData(url, getDefaultRequestInit('PATCH', payload));
  }

  async function getUser(payload) {
    const url = getUrl('/v1/contractors?taxNumber=' + payload.taxNumber +
      '&businessId=' + payload.businessId +
      '&employmentType=' + payload.employmentType);
    return await get(url);
  }

  async function getProjects() {
    const url = getUrl('/v1/contractors/self/projects');
    return await get(url);
  }

  async function getProject(number) {
    const url = getUrl('/v1/projects/' + number);
    return await get(url);
  }

  async function reportProject(payload) {
    const url = getUrl('/v1/timecards');
    return await post(url, payload);
  }

  async function stopTimeCards(payload = {}) {
    const url = getUrl('/v1/timecards/stop');
    return await patch(url, payload);
  }

  async function register(payload) {
    const url = getUrl('/v1/contractors');
    return await post(url, payload);
  }

  async function authenticate(payload) {
    const url = getUrl('/v1/contractors/tokens');
    const response = await post(url, payload);

    if (response.success) {
      appState.set({
        token: response.data.token
      });

      return true;
    }

    return false;
  }

  function logout() {
    appState.set({
      token: undefined
    });
  }

  function isAuthenticated() {
    const state = appState.get();
    if (state.token) {
      const decodedToken = jwt.decode(state.token, {complete: true});
      const dateNow = Math.floor(new Date().getTime() / 1000);

      if (decodedToken.payload.exp >= dateNow) {
        return true;
      }
    }

    return false;
  }

  function onAuthenticationRequest(callback) {
    authorizationCallback = callback;
  }

  return {
    getUser,
    getProjects,
    getProject,
    reportProject,
    register,
    authenticate,
    logout,
    isAuthenticated,
    onAuthenticationRequest,
    stopTimeCards
  };
})();

export default api;
