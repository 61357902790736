import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    verifyInfo: "Verify that the information is correct.",
    firstName: "First name",
    lastName: "Last name",
    next: "Next",
    back: "Back",
    confirm: "Confirm",
    title: "Project reporting",
    scanCard: "Scan Valtticard",
    introduction: "Every time you work at a workplace, you need to report on the project. To do this, scan your Valtticard and then follow the on-screen instructions.",
    scanUnable: "I can't scan my card",
    scanNoCard: "I don't have a Valtticard",
    notPersonalDevice: "Don't use your own device?",
    logoutDesc: "Click here to log out",
    leavingProject: "Are you leaving work?",
    leavingProjectDesc: "Click here to log out",
    cardManualDesc: "Enter your Valtticard number found under the barcode.",
    cardNumber: "Card number",
    taxNumberDesc: "Enter your tax number. If your tax number is not registered, you need to enter more information in the next step.",
    taxNumber: "Tax number",
    noPreviousProjects: "No previous projects...",
    previousProjects: "My previous projects...",
    choosePreviousProject: "Select a previous project.",
    supplyProjectCodeManually: "...or enter a new number manually.",
    projectCode: "Project number",
    projectName: "Project name",
    customerName: "Customer",
    yourFirstname: "Your first name",
    yourLastName: "Your last name",
    yourDateOfBirth: "Your date of birth",
    contactFirstname: "Contact person's first name",
    contactLastName: "Contact person's last name",
    contactPhoneNumber: "Contact person's phone number",
    companyName: "The name of the company",
    companyBusinessId: "Company business id",
    registrationConfirmation: "You are now registered in the system.",
    easierNextTime: "Next time you just need to enter your tax number in order to report to the right project.",
    unknownProjectCode: "Don't know your project number?",
    contactForeman: "Contact your foreman!",
    supplyFollowingInfo: "Enter the information below.",
    currentlyReportingToProject: "You are now reporting on project",
    allFieldsAreRequired: "All fields are mandatory.",
    errorOccurred: "An error occurred. Please make sure you have an active Internet connection and try again.",
    cardNumberNotFound: "The card number could not be found. Please check the number and try again.",
    noConnectionToServer: "Failed to connect to the server. Please make sure you have an active Internet connection and try again.",
    projectCodeNotFound: "The project number could not be found. Please check the number and try again.",
    invalidTaxNumberOrBusinessId: "The tax number or business id is invalid. Please check the details and try again.",
    notFoundInRemoteSource: "The data could not be supplemented by external sources. Please check the details and try again.",
    loginFailed: "Login failed. Please make sure you have an active Internet connection and try again.",
    errorTitle: "Error",
    worksAsLeasedEmployee: "Are you employed by a staffing company and work as a hired workforce?",
    yes: "Yes",
    no: "No",
    leasingCompanyInfo: "Enter the company you are leased to.",
    ok: "OK",
    dayPlaceholder: "dd",
    monthPlaceholder: "mm",
    yearPlaceholder: "yyyy",
    employmentType: "Employment type",
    employmentType1: "Direct employment",
    employmentType2: "Leased employee",
    employmentType3: "Independent contractor",
    employmentType4: "Unpaid worker",
  },
  sv: {
    verifyInfo: "Verifiera att uppgifterna stämmer.",
    firstName: "Förnamn",
    lastName: "Efternamn",
    next: "Nästa",
    back: "Tillbaka",
    confirm: "Bekräfta",
    title: "Projektrapportering",
    scanCard: "Skanna Valttikort",
    introduction: "Varje gång du arbetar på en arbetsplats behöver du rapportera in dig på projektet. Gör det genom att skanna ditt Valttikort och följ sedan instruktionerna på skärmen.",
    scanUnable: "Jag kan inte skanna mitt kort",
    scanNoCard: "Jag har inget Valttikort",
    notPersonalDevice: "Använder du inte din egen enhet?",
    logoutDesc: "Klicka här för att logga ut",
    leavingProject: "Lämnar du jobbet?",
    leavingProjectDesc: "Klicka här för att logga ut",
    cardManualDesc: "Ange ditt Valtti kortnummer du hittar under streckkoden.",
    cardNumber: "Kortnummer",
    taxNumberDesc: "Ange ditt skattenummer. Om ditt skattenummer inte är registrerat behöver du ange mer information i nästa steg.",
    taxNumber: "Skattenummer",
    noPreviousProjects: "Inga tidigare projekt...",
    previousProjects: "Mina tidigare projekt...",
    choosePreviousProject: "Välj ett tidigare projekt.",
    supplyProjectCodeManually: "...eller skriv in en ny kod manuellt.",
    projectCode: "Projektkod",
    projectName: "Projektnamn",
    customerName: "Kund",
    yourFirstname: "Ditt förnamn",
    yourLastName: "Ditt efternamn",
    yourDateOfBirth: "Ditt födelsedatum",
    contactFirstname: "Kontaktpersonens förnamn",
    contactLastName: "Kontaktpersonens efternamn",
    contactPhoneNumber: "Kontaktpersonens telefonnummer",
    companyName: "Företagets namn",
    companyBusinessId: "Företagets FO-nummer",
    registrationConfirmation: "Du är nu registrerad i systemet.",
    easierNextTime: "Nästa gång behöver du bara ange ditt skattenummer för att kunna rapportera till rätt projekt.",
    unknownProjectCode: "Vet du inte din projektkod?",
    contactForeman: "Kontakta din förman!",
    supplyFollowingInfo: "Ange nedanstående information.",
    currentlyReportingToProject: "Du rapporterar nu till projekt",
    allFieldsAreRequired: "Alla fält är obligatoriska.",
    errorOccurred: "Ett fel inträffade. Var god kontrollera att du har en aktiv Internet anslutning och försök igen.",
    cardNumberNotFound: "Kortnumret kunde inte hittas. Var god kontrollera numret och försök igen.",
    noConnectionToServer: "Servern kunde inte kontaktas. Var god kontrollera att du har en aktiv Internet anslutning och försök igen.",
    projectCodeNotFound: "Projektkoden kunde inte hittas. Var god kontrollera koden och försök igen.",
    invalidTaxNumberOrBusinessId: "Skattenumret eller FO-nummer är ogiltigt. Var god kontrollera uppgifterna och försök igen.",
    notFoundInRemoteSource: "Uppgifterna kunde inte kompletteras från externa källor. Var god kontrollera uppgifterna och försök igen.",
    loginFailed: "Inloggningen misslyckades. Var god kontrollera att du har en aktiv Internet uppkoppling och försök igen.",
    errorTitle: "Fel",
    worksAsLeasedEmployee: "Är du anställd av ett bemanningsföretag och arbetar som inhyrd arbetskraft?",
    yes: "Ja",
    no: "Nej",
    leasingCompanyInfo: "Ange företaget du är inhyrd hos.",
    ok: "OK",
    dayPlaceholder: "dd",
    monthPlaceholder: "mm",
    yearPlaceholder: "åååå",
    employmentType: "Anställningstyp",
    employmentType1: "Anställd",
    employmentType2: "Hyrd anställd",
    employmentType3: "Egenföretagare eller motsvarande",
    employmentType4: "Obetald arbetare",
  },
  fi: {
    verifyInfo: "Vahvista, että tiedot ovat oikein.",
    firstName: "Etunimi",
    lastName: "Sukunimi",
    next: "Seuraava",
    back: "Takaisin",
    confirm: "Vahvista",
    title: "Projektiraportointi",
    scanCard: "Skannaa Valttikortti",
    introduction: "Sinun tulee merkitä itsesi oikean projektin alle työskennellessäsi työmaalla. Toimiaksesi näin, skannaa Valttikortti ja seuraa näytön ohjeita.",
    scanUnable: "En voi skannata korttiani",
    scanNoCard: "Minulla ei ole Valttikorttia",
    notPersonalDevice: "Etkö käytä omaa laitetta?",
    logoutDesc: "Paina tästä kirjautuaksesi ulos",
    leavingProject: "Oletko lähdössä töistä?",
    leavingProjectDesc: "Paina tästä kirjautuaksesi ulos",
    cardManualDesc: "Syötä Valttikorttisi numero, joka löytyy viivakoodin alta.",
    cardNumber: "Kortin numero",
    taxNumberDesc: "Syötä veronumerosi. Sinun tulee syöttää lisätietoja seuraavassa vaiheessa, mikäli veronumeroasi ei ole rekisteröity.",
    taxNumber: "Veronumero",
    noPreviousProjects: "Ei aikaisempia projekteja...",
    previousProjects: "Aikaisemmat projektini...",
    choosePreviousProject: "Valitse viimeisin projekti.",
    supplyProjectCodeManually: "...tai syötä uusi numero manuaalisesti.",
    projectCode: "Projektin numero",
    projectName: "Projektin nimi",
    customerName: "Asiakas",
    yourFirstname: "Etunimesi",
    yourLastName: "Sukunimesi",
    yourDateOfBirth: "Syntymäaikasi",
    contactFirstname: "Yhteyshenkilön etunimi",
    contactLastName: "Yhteyshenkilön sukunimi",
    contactPhoneNumber: "Yhteyshenkilön puhelinnnumero",
    companyName: "Yrityksen nimi",
    companyBusinessId: "Yrityksen y-tunnus",
    registrationConfirmation: "Olet nyt rekisteröitynyt järjestelmään.",
    easierNextTime: "Sinun tulee ensi kerralla vain syöttää veronumerosi merkitäksesi itsesi oikean projektin alle.",
    unknownProjectCode: "Etkö tiedä projektin numeroa?",
    contactForeman: "Ota yhteys esimieheesi!",
    supplyFollowingInfo: "Syötä alla olevat tiedot.",
    currentlyReportingToProject: "Olet nyt merkitsemässäsi itseäsi projektin alle",
    allFieldsAreRequired: "Kaikki kentät ovat pakollisia.",
    errorOccurred: "Tapahtui virhe. Varmista, että sinulla on toimiva verkkoyhteys päällä ja yritä uudelleen.",
    cardNumberNotFound: "Korttinumeroa ei löytynyt. Tarkista numero ja yritä uudelleen.",
    noConnectionToServer: "Yhteys palvelimeen epäonnistui. Varmista, että sinulla on toimiva verkkoyhteys päällä ja yritä uudelleen.",
    projectCodeNotFound: "Projektin numeroa ei löytynyt. Tarkista numero ja yritä uudelleen.",
    invalidTaxNumberOrBusinessId: "Virheellinen veronumero tai y-tunnus. Tarkista tiedot ja yritä uudelleen.",
    notFoundInRemoteSource: "Tietoja ei voitu varmentaa ulkoisista lähteistä. Tarkista tiedot ja yritä uudelleen.",
    loginFailed: "Sisäänkirjautuminen epäonnistui. Varmista, että sinulla on toimiva verkkoyhteys päällä ja yritä uudelleen",
    errorTitle: "Virhe",
    worksAsLeasedEmployee: "Onko sinut palkattu vuokratyöfirman kautta ja työskentelet vuokratyöntekijänä?",
    yes: "Joo",
    no: "Ei",
    leasingCompanyInfo: "Lisää yritys, jolle sinut on vuokrattu.",
    ok: "OK",
    dayPlaceholder: "pp",
    monthPlaceholder: "kk",
    yearPlaceholder: "vvvv",
    employmentType: "Työsuhteen tyyppi",
    employmentType1: "Työsuhteinen",
    employmentType2: "Vuokratyöntekijä",
    employmentType3: "Ammatinharjoittaja tai vastaava",
    employmentType4: "Palkaton työntekijä",
  },
});

export default strings;
