const appState = (function () {
  const key = 'app-state';
  let isLoaded = false;
  let state = {};

  const storeState = function () {
    localStorage.setItem(key, JSON.stringify(state));
    isLoaded = true;
  };

  const set = function (newState) {
    state = {
      ...state,
      ...newState
    };
    storeState();
  };

  const loadState = function () {
    if (!isLoaded) {
      const storedState = localStorage.getItem(key);
      if (storedState) {
        set(JSON.parse(storedState));
      }
    }

    isLoaded = true;
  };

  const get = function () {
    loadState();
    return state;
  };

  return {
    get,
    set
  };
})();

export default appState;
