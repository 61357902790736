import React, {Component} from 'react';
import {Textbox} from "./Textbox";
import api from "../api";
import config from '../config';
import {scrollToTop} from "../helpers";
import Select from 'react-select';
import './ProjectHome.css';
import PropTypes from "prop-types";
import strings from "../strings";
import alerts from "./Alerts";
import {handleBusinessId, handleText} from "../input_handlers";
import * as EmploymentType from "../constants/EmploymentTypes";

export class ProjectHome extends Component {
  static propTypes = {
    projects: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    setUserAuxData: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      input: {
        number: {value: '', isValid: false}
      }
    };

    if (props.user.employmentType === EmploymentType.LeasedEmployee) {
      let prevValue = {
        companyName: {value: '', isValid: false},
        businessId: {value: '', isValid: false}
      };

      if (props.user.aux) {
        prevValue = {
          companyName: {value: props.user.aux.companyName, isValid: true},
          businessId: {value: props.user.aux.businessId, isValid: true}
        };
      }

      this.state.input.companyName = prevValue.companyName;
      this.state.input.businessId = prevValue.businessId;
    }
  }

  componentDidMount() {
    scrollToTop();
  }

  confirmRegister = async (e) => {
    e.preventDefault();

    const projectNumber = this.state.input.number.value;
    const result = await api.getProject(projectNumber);

    if (result.success) {
      // The given project code was correct, project info returned
      const timeCard = {...result.data};

      if (this.props.user.employmentType === EmploymentType.LeasedEmployee) {
        timeCard.companyName = this.state.input.companyName.value;
        timeCard.businessId = this.state.input.businessId.value;

        this.props.setUserAuxData({
          companyName: timeCard.companyName,
          businessId: timeCard.businessId
        });
      }

      this.props.history.push({
        pathname: '/project-report',
        state: timeCard
      });
    } else {
      // Some error occurred
      if (result.status === 404) {
        // Project code not found
        alerts.error(strings.projectCodeNotFound);
      } else {
        alerts.error(strings.errorOccurred);
      }
    }
  };

  isValid = () => {
    const keys = Object.keys(this.state.input);

    for (let index in keys) {
      const key = keys[index];

      if (!this.state.input[key].isValid) {
        return false;
      }
    }

    return true;
  };

  handleSelect = (e) => {
    const state = {...this.state};
    let newValue = e.value.toString();

    state.input.number.value = newValue;
    state.input.number.isValid = newValue &&
      newValue.length >= config.project.length.min &&
      newValue.length <= config.project.length.max;

    this.setState(state);
  };

  handleNumber = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    let newValue = e.currentTarget.value
      .replace(/[^\d]/g, '');

    state.input[key].value = newValue;
    state.input[key].isValid = newValue &&
      newValue.length >= config.project.length.min &&
      newValue.length <= config.project.length.max;

    this.setState(state);
  };

  handleBusinessId = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    const oldValue = state.input[key].value;
    const {value, isValid} = handleBusinessId(oldValue, e.currentTarget.value);

    state.input[key].value = value;
    state.input[key].isValid = isValid;

    this.setState(state);
  };

  handleCompanyName = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    const {value, isValid} = handleText(e.currentTarget.value, 1, 200);

    state.input[key].value = value;
    state.input[key].isValid = isValid;

    this.setState(state);
  };

  getProjectOptions = () => {
    const options = {};
    const projects = [...this.props.projects];
    projects.sort((a, b) => {
      const aDate = Date.parse(a.lastReportedAt);
      const bDate = Date.parse(b.lastReportedAt);

      if (aDate < bDate) {
        return 1;
      }

      if (aDate > bDate) {
        return -1;
      }

      return 0;
    });

    for (let i = 0; i < projects.length; i++) {
      const proj = projects[i];
      options[`proj${proj.number}`] = {
        value: proj.number,
        label: proj.name,
        customer: proj.customer
      }
    }

    return options;
  };

  render() {
    const options = this.getProjectOptions();
    const optionValues = Object.values(options);
    let defaultOption = options[`proj${this.state.input.number.value}`];
    if (!defaultOption) {
      defaultOption = null;
    }

    let selectList = (<p className="disabled prev-project">{strings.noPreviousProjects}</p>);
    if (optionValues.length > 0) {
      const formatOptionLabel = ({value, label, customer}) => (
        <div className="project">
          <span className="number">{value}</span>
          <span className="description">{label}</span>
          <span className="customer">{customer}</span>
        </div>
      );

      selectList = (<Select
        className="prev-project"
        classNamePrefix="select"
        isSearchable={false}
        placeholder={strings.previousProjects}
        formatOptionLabel={formatOptionLabel}
        options={optionValues}
        value={defaultOption}
        onChange={this.handleSelect}
      />);
    }

    let leasingCompany = null;

    if (this.props.user.employmentType === EmploymentType.LeasedEmployee) {
      leasingCompany = (
        <div>
          <p>{strings.leasingCompanyInfo}</p>
          <Textbox
            value={this.state.input.companyName.value}
            name="companyName"
            key="companyName"
            type="text"
            label={strings.companyName}
            required={true}
            onChange={this.handleCompanyName}/>
          <Textbox
            value={this.state.input.businessId.value}
            name="businessId"
            key="businessId"
            type="tel"
            label={strings.companyBusinessId}
            required={true}
            onChange={this.handleBusinessId}/>
        </div>
      );
    }

    return (
      <form className="project home" onSubmit={this.confirmRegister}>
        <p>{strings.choosePreviousProject}</p>
        {selectList}
        <p>{strings.supplyProjectCodeManually}</p>
        <Textbox
          value={this.state.input.number.value}
          name="number"
          key="number"
          type="number"
          label={strings.projectCode}
          required={true}
          onChange={this.handleNumber}
          others={{
            pattern: `^\\d{${config.project.length.min},${config.project.length.max}}$`,
            maxLength: config.project.length.max
          }}/>
        {leasingCompany}
        <ul>
          <li>
            <button disabled={!this.isValid()} type="submit">{strings.next}</button>
          </li>
        </ul>
        <p>
          {strings.unknownProjectCode}<br/>
          {strings.contactForeman}
        </p>
      </form>
    );
  }
}
