import './DateOfBirth.css';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class DateOfBirth extends Component {
  static propTypes = {
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    delimiter: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholders: PropTypes.shape({
      day: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      year: PropTypes.string.isRequired
    }).isRequired,
  };

  state = {
    day: '',
    month: '',
    year: '',
  };

  componentDidMount() {
    const initialValue = this.props.value;

    if (initialValue) {
      try {
        const date = new Date(Date.parse(initialValue.toString()));
        if (date) {
          this.setState({
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear()
          });
        }
      } catch (e) {

      }
    }
  }

  parseAndValidate = (key, value) => {
    const stringValue = value.toString();
    let newValue = stringValue.replace(/\D/g, '');
    let valid = true;

    if (newValue.length > 0) {
      newValue = parseInt(newValue, 10);

      switch (key) {
        case 'day':
          valid = newValue >= (stringValue.length === 1 ? 0 : 1) && newValue <= 31;
          break;
        case 'month':
          valid = newValue >= (stringValue.length === 1 ? 0 : 1) && newValue <= 12;
          break;
        case 'year':
          valid = newValue <= new Date().getFullYear();
          break;
        default:
          valid = false;
      }
    }

    return {
      valid,
      value: newValue
    };
  };

  handleChange = (e) => {
    const key = e.currentTarget.name;
    const {value, valid} = this.parseAndValidate(key, e.currentTarget.value);

    if (valid) {
      const state = {
        ...this.state,
        [key]: value
      };
      this.setState(state);

      let dateValue = '';
      try {
        const date = new Date(state.year + '-' + state.month + '-' + state.day + 'Z');

        if (date.getDate() === parseInt(state.day, 10)) {
          dateValue = date.toISOString().substr(0, 10);
        }
      } catch (e) {

      }

      this.props.onChange({
        currentTarget: {
          name: this.props.name,
          value: dateValue
        }
      });
    }
  };

  render() {
    const {label, required, disabled, delimiter, placeholders} = this.props;

    return (
      <label className={(disabled ? 'disabled ' : '') + 'date-of-birth'}>
        <span className="label">{label}</span>
        <input type="number" value={this.state.day} onChange={this.handleChange} placeholder={placeholders.day} className="day"
               key={"day"} name={"day"} disabled={disabled} required={required}/>
        <span className="delimiter">{delimiter}</span>
        <input type="number" value={this.state.month} onChange={this.handleChange} placeholder={placeholders.month} className="month"
               key={"month"} name={"month"} disabled={disabled} required={required}/>
        <span className="delimiter">{delimiter}</span>
        <input type="number" value={this.state.year} onChange={this.handleChange} placeholder={placeholders.year} className="year"
               key={"year"} name={"year"} disabled={disabled} required={required}/>
      </label>
    );
  }
}
