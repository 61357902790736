import React, {Component} from 'react';
import Quagga from 'quagga';
import config from "../config";
import './LoginCard.css';
import api from "../api";
import PropTypes from "prop-types";
import strings from "../strings";
import alerts from "./Alerts";
import * as EmploymentType from "../constants/EmploymentTypes";

export class LoginCard extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired
  };

  startScan = () => {
    Quagga.onDetected(this.scanCallback);
    Quagga.start();
  };

  tryLogin = (user) => {
    const self = this;

    user.employmentTypeVerified = (user.employmentType !== EmploymentType.DirectEmployment);
    this.props.login(user).then(success => {
      if (success) {
        self.props.history.go(-1);
      } else {
        // Could not log in
        alerts.error(strings.loginFailed);

        // Continue scanning
        self.startScan();
      }
    });
  };

  handleError = (status) => {
    switch (status) {
      case 404:
        // Card number not found
        alerts.error(strings.cardNumberNotFound, this.startScan);
        break;
      case 408:
        // No Internet or server is down
        alerts.error(strings.noConnectionToServer, this.startScan);
        break;
      default:
        alerts.error(strings.errorOccurred, this.startScan);
        break;
    }
  };

  scans = {};
  scanCallback = (data, result) => {
    const self = this;
    const cardNumber = data.codeResult.code;

    if (cardNumber.length !== config.barcode.length) {
      // Wrong length, discard
      return;
    }

    const key = `c${cardNumber}`;
    this.scans[key] = (this.scans[key] || 0) + 1;
    if (this.scans[key] < config.barcode.repeatedScans) {
      // Not enough scans for this code
      return;
    }

    this.scans[key] = 0;
    Quagga.offDetected(this.scanCallback);
    Quagga.pause();
    result.style["display"] = "block";
    result.innerText = cardNumber;
    api.register({cardNumber})
      .then(result => {
        if (result.success) {
          // The given card number was correct, user returned
          self.tryLogin(result.data);
        } else {
          // Some error occurred
          self.handleError(result.status);
        }
      });
  };

  componentDidMount() {
    const self = this;
    const scanner = document.getElementById('barcode-scanner');
    const result = document.getElementById('barcode-result');
    const settings = config.barcode.valtti;

    settings.inputStream.target = scanner;

    // This try catch is a work around when the render test fails because
    // the functionality this library uses does not exist
    try {
      Quagga.init(settings, function (err) {
        if (err) {
          self.props.history.replace('/login/card/manual');
          return;
        }
        Quagga.start();
      });

      Quagga.onDetected((data) => this.scanCallback(data, result));
    } catch (e) {
    }
  }

  componentWillUnmount() {
    Quagga.offDetected(this.scanCallback);
    Quagga.stop();
  }

  render() {
    return (
        <div className={"login-card"}>
          <div id="barcode-result" className="barcode-result"></div>
          <div id="barcode-scanner" className="barcode-scanner"/>
        </div>
    );
  }
}
