import React, {Component} from 'react';
import {Textbox} from "./Textbox";
import api from "../api";
import config from '../config';
import {scrollToTop} from "../helpers";
import PropTypes from "prop-types";
import strings from "../strings";
import alerts from "./Alerts";
import * as EmploymentType from "../constants/EmploymentTypes";

export class LoginCardManual extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired
  };

  state = {
    input: {
      number: {value: '', isValid: false}
    }
  };

  componentDidMount() {
    scrollToTop();
  }

  register = async (e) => {
    e.preventDefault();

    const cardNumber = this.state.input.number.value;
    const result = await api.register({cardNumber});

    if (result.success) {
      // The given card number was correct, user returned
      const user = result.data;
      user.employmentTypeVerified = (user.employmentType !== EmploymentType.DirectEmployment);

      this.props.login(user).then(success => {
        if (success) {
          this.props.history.go(-1);
        } else {
          // Could not log in
          alerts.error(strings.loginFailed);
        }
      });
    } else {
      // Some error occurred
      switch (result.status) {
        case 404:
          // Card number not found
          alerts.error(strings.cardNumberNotFound);
          break;
        case 408:
          // No Internet or server is down
          alerts.error(strings.noConnectionToServer);
          break;
        default:
          alerts.error(strings.errorOccurred);
          break;
      }
    }
  };

  isValid = () => {
    const keys = Object.keys(this.state.input);

    for (let index in keys) {
      const key = keys[index];

      if (!this.state.input[key].isValid) {
        return false;
      }
    }

    return true;
  };

  handleNumber = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    let newValue = e.currentTarget.value.replace(/\D/g, '').substr(0, config.barcode.length);

    state.input[key].value = newValue;
    state.input[key].isValid = newValue && newValue.length === config.barcode.length;

    this.setState(state);
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    return (
      <form className="login card manual" onSubmit={this.register}>
        <p>{strings.cardManualDesc}</p>
        <Textbox
          value={this.state.input.number.value}
          name="number"
          key="number"
          type="number"
          label={strings.cardNumber}
          required={true}
          onChange={this.handleNumber}
          others={{pattern: '^\\d{12}$'}}/>
        <ul>
          <li>
            <button disabled={!this.isValid()} type="submit">{strings.next}</button>
          </li>
          <li>
            <button className="link" onClick={this.goBack}>{strings.back}</button>
          </li>
        </ul>
      </form>
    );
  }
}
