import {FinnishBusinessIds} from "finnish-business-ids";

export function handleBusinessId(oldValue, value) {
  let newValue = value.replace(/\D/g, '');

  if (newValue.length === 7) {
    if (!oldValue || oldValue.length <= newValue.length) {
      newValue = newValue.replace(/^(\d{7})(\d?)/, '$1-$2');
    }
  } else if (newValue.length > 7) {
    newValue = newValue.replace(/^(\d{7})(\d?)/, '$1-$2');
  }

  newValue = newValue.substring(0, 9);

  return {
    value: newValue,
    isValid: FinnishBusinessIds.isValidBusinessId(newValue)
  };
}

export function handleText(value, minLength, maxLength) {
  let newValue = value
    .replace(/\s{2,}/g, ' ')
    .replace(/-{2,}/g, '-');

  return {
    value: newValue,
    isValid: newValue && newValue.length >= minLength && newValue.length <= maxLength
  };
}
