import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isValidProjectLoad, scrollToTop} from "../helpers";
import strings from "../strings";
import {StaticText} from "./StaticText";
import api from "../api";
import alerts from "./Alerts";

export class ProjectDetails extends Component {
  static propTypes = {
    addProject: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    if (!isValidProjectLoad(props)) {
      props.history.replace('/');
    }
  }

  componentDidMount() {
    scrollToTop();
  }

  report = async (e) => {
    e.preventDefault();

    const timeCard = {
      projectNumber: this.props.location.state.number
    };

    if (this.props.location.state.businessId) {
      timeCard.leasingCompany = {
        businessId: this.props.location.state.businessId,
        companyName: this.props.location.state.companyName
      }
    }

    const result = await api.reportProject(timeCard);

    if (result.success) {
      const project = {...this.props.location.state};
      project.lastReportedAt = result.data.createdAt;
      this.props.addProject(project);

      this.props.history.replace({
        pathname: '/project-report/confirmation',
        state: project
      });
    } else {
      // Some error occurred
      alerts.error(strings.errorOccurred);
    }
  };

  goBack = (e) => {
    e.preventDefault();

    this.props.history.goBack();
  };

  render() {
    if (!isValidProjectLoad(this.props)) {
      return null;
    }

    return (
      <form className="project report confirm" onSubmit={this.report}>
        <p>{strings.verifyInfo}</p>
        <StaticText value={this.props.location.state.number} label={strings.projectCode}/>
        <StaticText value={this.props.location.state.name} label={strings.projectName}/>
        <StaticText value={this.props.location.state.customer} label={strings.customerName}/>
        <ul>
          <li>
            <button type="submit">{strings.confirm}</button>
          </li>
          <li>
            <button className="link" onClick={this.goBack}>{strings.back}</button>
          </li>
        </ul>
      </form>
    );
  }
}
