import React, {Component} from 'react';
import {Textbox} from "./Textbox";
import api from "../api";
import {scrollToTop} from "../helpers";
import strings from "../strings";
import {DateOfBirth} from "./DateOfBirth";
import {handleBusinessId, handleText} from "../input_handlers";

export class Register extends Component {
  state = {
    user: {
      firstName: {value: '', isValid: false},
      lastName: {value: '', isValid: false},
      dateOfBirth: {value: '', isValid: false},
      companyName: {value: '', isValid: false},
      businessId: {value: '', isValid: false},
      contactFirstName: {value: '', isValid: false},
      contactLastName: {value: '', isValid: false},
      contactPhone: {value: '', isValid: false},
    }
  };

  componentDidMount() {
    scrollToTop();

    const state = {...this.state};
    const {value, isValid} = handleBusinessId(this.props.location.state.businessId, this.props.location.state.businessId);
    state.user.businessId.value = value;
    state.user.businessId.isValid = isValid;

    this.setState(state);
  }

  isValid = () => {
    const keys = Object.keys(this.state.user);

    for (let index in keys) {
      const key = keys[index];

      if (!this.state.user[key].isValid) {
        return false;
      }
    }

    return true;
  };

  register = async (e) => {
    e.preventDefault();

    const user = {...this.props.location.state};
    const keys = Object.keys(this.state.user);
    for (let index in keys) {
      const key = keys[index];
      user[key] = this.state.user[key].value.replace(/^\s+|\s+$/, '');
    }

    const result = await api.register(user);

    if (result.success) {
      // The request was successfully handled
      this.props.history.replace({
        pathname: '/register/confirmation',
        state: {
          user: result.data
        }
      });
    }
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleDate = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    let newValue = e.currentTarget.value;

    state.user[key].value = newValue;

    if (newValue.length > 0) {
      const dateDiff = (new Date() - Date.parse(newValue)) / 1000;
      const yearDiff = dateDiff / (60 * 60 * 24 * 365.25);

      state.user[key].isValid = yearDiff > 10 && yearDiff < 80;
    } else {
      state.user[key].isValid = false;
    }

    this.setState(state);
  };

  handleText = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    const {value, isValid} = handleText(e.currentTarget.value, 1, 200);

    state.user[key].value = value;
    state.user[key].isValid = isValid;

    this.setState(state);
  };

  handlePhone = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    let newValue = e.currentTarget.value
      .replace(/[^\-+\d]/g, '');

    state.user[key].value = newValue;
    state.user[key].isValid = newValue && newValue.length > 6;

    this.setState(state);
  };

  handleBusinessId = (e) => {
    const key = e.currentTarget.name;
    const state = {...this.state};
    const oldValue = state.user[key].value;
    const {value, isValid} = handleBusinessId(oldValue, e.currentTarget.value);

    state.user[key].value = value;
    state.user[key].isValid = isValid;

    this.setState(state);
  };

  render() {
    const txtRender = props => <Textbox {...props}/>;
    const dobRender = props => <DateOfBirth {...props}/>;

    const fields = {
      firstName: {renderer: txtRender, data: {label: strings.yourFirstname, onChange: this.handleText, type: "text"}},
      lastName: {renderer: txtRender, data: {label: strings.yourLastName, onChange: this.handleText, type: "text"}},
      dateOfBirth: {
        renderer: dobRender, data: {
          label: strings.yourDateOfBirth, onChange: this.handleDate, delimiter: '.', placeholders: {
            day: strings.dayPlaceholder,
            month: strings.monthPlaceholder,
            year: strings.yearPlaceholder
          }
        }
      },
      companyName: {renderer: txtRender, data: {label: strings.companyName, onChange: this.handleText, type: "text"}},
      businessId: {
        renderer: txtRender,
        data: {label: strings.companyBusinessId, onChange: this.handleBusinessId, type: "tel", disabled: true}
      },
      contactFirstName: {
        renderer: txtRender,
        data: {label: strings.contactFirstname, onChange: this.handleText, type: "text"}
      },
      contactLastName: {
        renderer: txtRender,
        data: {label: strings.contactLastName, onChange: this.handleText, type: "text"}
      },
      contactPhone: {
        renderer: txtRender,
        data: {
          label: strings.contactPhoneNumber, onChange: this.handlePhone, type: "tel", others: {
            pattern: '^(\\+[1-9][0-9]{3,15}|0[1-9][0-9]{0,1}-?[0-9]{3,13})$'
          }
        }
      }
    };

    const fieldIds = Object.keys(fields);

    return (
      <form className="register" onSubmit={this.register}>
        <p>
          {strings.supplyFollowingInfo}<br/>
          {strings.allFieldsAreRequired}
        </p>
        {
          fieldIds.map(key => fields[key].renderer({
            ...fields[key].data,
            value: this.state.user[key].value,
            key: key,
            name: key,
            required: true
          }))
        }
        <ul>
          <li>
            <button disabled={!this.isValid()} type="submit">{strings.next}</button>
          </li>
          <li>
            <button className="link" onClick={this.goBack}>{strings.back}</button>
          </li>
        </ul>
      </form>
    );
  }
}
