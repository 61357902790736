const config = {
  api: {
    baseUrl: '${BASE_URL}'
  },
  taxNumber: {
    length: 12
  },
  project: {
    length: {
      min: 4,
      max: 6
    }
  },
  barcode: {
    length: 12,
    repeatedScans: 3,
    valtti: {
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: null
      },
      locator: {
        patchSize: "large",
        halfSample: false
      },
      numOfWorkers: 4,
      frequency: 10,
      decoder: {
        readers: ["i2of5_reader"],
        multiple: false
      },
      locate: true
    }
  }
};

export default config;
